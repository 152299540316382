body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  .phone-col12 {
    padding-right: 5px!important;
    padding-left: 5px!important;
  }
.carousel .slide img {
  width: 100%!important;
  vertical-align: top!important;
  border: 0!important;
  object-fit: fill!important;
  height: 95px!important;
  margin-bottom: .5rem;
  border-radius: 20px;
}
}

@media (min-width: 768px) {
  .carousel .slide img {
    width: 100%!important;
    vertical-align: top!important;
    border: 0!important;
    object-fit: fill!important;
    height: 250px!important;
    border-radius: 20px;
  }
  }

  @import url(./bibeet.css);
